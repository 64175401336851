export const API_VERSION = '1.0';
export const CURRENT_SERVER = 'https://church91.impactfactors.net';
export const SECOND_SERVER = 'https://base.impactfactors.net';
export const THIRD_SERVER = 'https://base6.impactfactors.net';
// export const SECOND_SERVER = 'https://two_church.impactfactors.net'
// export const SECOND_SERVER = 'https://gracechurch.impactfactors.net'
// export const PRICING_DOMEN = 'https://welcome.impactfactors.net/api.php/w/'
export const PRICING_DOMEN = 'https://impactfactors.net/welcome-api/w/';
export const CHURCH_DOMEN = 'https://impactfactors.net/welcome-api/church/';
// export const PRICING_DOMEN = 'https://welcome.impactfactors.net/api.php/w/';
// export const CHURCH_DOMEN = 'https://welcome.impactfactors.net/api.php/church/';
export const GEO_DOMEN = 'https://impactfactors.net/welcome-api/w/';
export const CORP_DOMAIN = `${SECOND_SERVER}/api/corp/action/${API_VERSION}/`;
export const MEMBER_DOMAIN = `${SECOND_SERVER}/api/members/${API_VERSION}/`;
// export const AMO_CRM_DOMAIN = `${CURRENT_SERVER}/api/amocrm/leads/${API_VERSION}/`;
export const AMO_CRM_DOMAIN = `${THIRD_SERVER}/api/forms/${API_VERSION}`;
// export const MEMBERS_DOMAIN = SECOND_SERVER + '/api/member/' + API_VERSION + '/'
