// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueAnime from 'vue-animejs';
import VueEsc from 'vue-esc';
import axios from 'axios';
import VueAxios from 'vue-axios';
// import VeeValidate from 'vee-validate'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VTooltip from 'v-tooltip';
import { WOW } from 'wowjs';
// import VueTypedJs from 'vue-typed-js'
import VueGtag from 'vue-gtag';
import { store } from './assets/store/index';
import router from './router';
import App from './App';
import { VLazyImagePlugin } from './assets/modules/v-lazy-image';

window.WOW = WOW;
// window.lottie = require('./home-assets/js/lottie.min.js')

Vue.use(VLazyImagePlugin);

const VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo);
Vue.use(VueAxios, axios);
Vue.use(VTooltip);

// Vue.use(VueTypedJs)

Vue.use(VueAnime);
Vue.use(VueEsc);
// Vue.use(VeeValidate)
Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);
Vue.use(require('vue-shortkey'));

Vue.directive('focus', {
  inserted(el) {
    el.focus();
  },
});
Vue.use(VueGtag, {
  config: {
    id: 'AW-699985331',
    groups: 'app',
    params: {
      send_page_view: false,
    },
  },
  includes: [
    {
      id: 'AW-1011441392',
      groups: 'form',
      params: {
        send_page_view: false,
      },
    },
    {
      id: 'GTM-PBVXF53',
      groups: 'app',
      params: {
        send_page_view: false,
      },
    },
    {
      id: 'GTM-K6CG9HK',
      defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    },
  ],
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>',
});
