import Vue from 'vue';
import Router from 'vue-router';
import { routes } from './routes';

Vue.use(Router);
// const base = process.env.NODE_ENV === 'production' ? '/widget/site/' : '/';

const router = new Router({
  mode: 'history',
  // base,
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 0);
    });
  },
});

// router.beforeEach((to, from, next) => {
//   console.log('to', to);
//   if (to) {
//     const { meta = {} } = to;
//     const { title } = meta;
//     if (title) {
//       document.title = title;
//     }
//   }
//   next();
// });

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
  document.title = nearestWithTitle ? nearestWithTitle.meta.title : 'ImpactFactors - Next Generation Websites for Churches & Ministries';
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));
  if (!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags.map((tagDef) => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach((key) => {
      tag.setAttribute(key, tagDef[key]);
    });
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach((tag) => document.head.appendChild(tag));
  next();
});

export default router;

// Yuriy Horzey
// 4036 2313 0076 3621
// 07/23
// 356
//
// 3626 N Hall Str.
//   Suite 610
// Dallas, TX
// 75219
