import { AMO_CRM_DOMAIN } from '@/constants/domains';

const axios = require('axios');

export default {
  sendLeadsToAmoSrm({ data, id }) {
    const instWithCred = axios.create({
      baseURL: `${AMO_CRM_DOMAIN}/${id}/submit`,
    });
    return instWithCred.post('', data);
  },
};
