const VLazyImageComponent = {
  props: {
    src: {
      type: String,
      required: true,
    },
    srcPlaceholder: {
      type: String,
      default: '',
    },
    srcset: {
      type: String,
    },
    intersectionOptions: {
      type: Object,
      default: () => ({}),
    },
    usePicture: {
      type: Boolean,
      default: false,
    },
  },
  inheritAttrs: false,
  data: () => ({ observer: null, intersected: false, loaded: false }),
  computed: {
    timeCheckDefault: {
      get() {
        return this.timeCheckDefault;
      },
      set(data) {
        this.timeCheckDefault = data;
      },
    },
    srcImage() {
      return this.intersected ? this.src : this.srcPlaceholder;
    },
    srcsetImage() {
      return this.intersected && this.srcset ? this.srcset : false;
    },
  },
  methods: {
    isInViewport() {
      const bounding = this.$el.getBoundingClientRect();
      return (
        (bounding.top >= 0
          && bounding.bottom > 0
        && bounding.left >= 0
        && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && bounding.right <= (window.innerWidth || document.documentElement.clientWidth))
        || (
          bounding.bottom > 0
          && bounding.top >= 0 && bounding.top < window.innerHeight
        )
      );
    },
    load() {
      if (this.$el.getAttribute('src') !== this.srcPlaceholder) {
        this.loaded = true;
        this.$emit('load');
      }
    },
  },
  render(h) {
    const img = h('img', {
      attrs: {
        src: this.srcImage,
        srcset: this.srcsetImage,
      },
      domProps: this.$attrs,
      class: {
        'v-lazy-image': true,
        'v-lazy-image-loaded': this.loaded,
      },
      on: { load: this.load },
    });
    if (this.usePicture) {
      return h('picture', this.intersected ? [this.$slots.default, img] : []);
    }
    return img;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isInViewport()) {
        this.intersected = true;
        this.observer.disconnect();
        this.$emit('intersect');
      }
      setTimeout(() => {
        this.intersected = true;
        this.observer.disconnect();
        this.$emit('intersect');
      }, 10000);
    });
    if ('IntersectionObserver' in window) {
      if (!this.intersected) {
        this.observer = new IntersectionObserver((entries) => {
          const image = entries[0];
          if (image.isIntersecting) {
            this.intersected = true;
            this.observer.disconnect();
            this.$emit('intersect');
          }
        }, this.intersectionOptions);
        this.observer.observe(this.$el);
      }
    } else {
      console.error(
        'v-lazy-image: this browser doesn\'t support IntersectionObserver. Please use this polyfill to make it work https://github.com/w3c/IntersectionObserver/tree/master/polyfill.',
      );
    }
  },
  destroyed() {
    this.observer.disconnect();
  },
};

export default VLazyImageComponent;

export const VLazyImagePlugin = {
  install: (Vue, opts) => {
    Vue.component('VLazyImage', VLazyImageComponent);
  },
};
