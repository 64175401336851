import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    additionalOptionsDef: [
      {
        id: 1,
        title: 'iTunes Video Podcast. $%price%/month',
        checked: false,
        pay: true,
        key: 'itunes_video',
        additionals: true,
      },
    ],
    tvAppList: [
      {
        id: 1,
        title: 'Apple TV',
        checked: false,
        pay: false,
        key: 'itv',
        platforms: true,
      },
      {
        id: 2,
        title: 'ROKU',
        checked: false,
        pay: false,
        key: 'roku',
        platforms: true,
      },
      {
        id: 3,
        title: 'Android TV',
        checked: false,
        pay: false,
        key: 'google',
        platforms: true,
      },
      {
        id: 4,
        title: 'Amazon Fire TV',
        checked: false,
        pay: false,
        key: 'amazon',
        platforms: true,
      },
    ],
    tvAppModulesList: [
      {
        id: 1,
        title: 'Sermons',
        checked: false,
        pay: false,
        key: 'sermons',
        tv: true,
      },
      {
        id: 2,
        title: 'Music',
        checked: false,
        pay: false,
        key: 'music',
        tv: true,
      },
      {
        id: 3,
        title: 'Live',
        checked: false,
        pay: false,
        key: 'live',
        tv: true,
      },
      {
        id: 4,
        title: 'Photos',
        checked: false,
        pay: false,
        key: 'media',
        tv: true,
      },
    ],
    audioPodcastList: [
      {
        id: 1,
        title: 'All Major Platforms',
        checked: false,
        pay: false,
        key: 'itunes_audio',
        additionals: true,
      },
    ],
    selectAllModules: [
      {
        id: 0,
        text: 'Select All',
        checked: false,
        key: 'select',
      },
    ],
    mobileAppModulesList: [
      {
        id: 1,
        text: 'Sermons',
        checked: false,
        pay: false,
        key: 'sermons',
        mobile: true,
      },
      {
        id: 2,
        text: 'Events',
        checked: false,
        pay: false,
        key: 'events',
        mobile: true,
      },
      {
        id: 3,
        text: 'About Us',
        checked: false,
        pay: false,
        key: 'aboutUs',
        mobile: true,
      },
      {
        id: 4,
        text: 'News',
        checked: false,
        pay: false,
        key: 'news',
        mobile: true,
      },
      {
        id: 5,
        text: 'Blogs',
        checked: false,
        pay: false,
        key: 'blog',
        mobile: true,
      },
      {
        id: 6,
        text: 'Devotions',
        checked: false,
        pay: false,
        key: 'devotional',
        mobile: true,
      },
      {
        id: 7,
        text: 'Reading Plan',
        checked: false,
        pay: false,
        key: 'reading_plan',
        mobile: true,
      },
      {
        id: 8,
        text: 'Bible',
        checked: false,
        pay: false,
        key: 'bible',
        mobile: true,
      },
      {
        id: 9,
        text: 'Ministries',
        checked: false,
        pay: false,
        key: 'ministries',
        mobile: true,
      },
      {
        id: 10,
        text: 'Staﬀ',
        checked: false,
        pay: false,
        key: 'staff',
        mobile: true,
      },
      {
        id: 11,
        text: 'Photos',
        checked: false,
        pay: false,
        key: 'media',
        mobile: true,
      },
      {
        id: 12,
        text: 'Contact Us',
        checked: false,
        pay: false,
        key: 'followUs',
        mobile: true,
      },
      {
        id: 13,
        text: 'Live (streaming link required)',
        checked: false,
        pay: false,
        key: 'live',
        mobile: true,
      },
      {
        id: 14,
        text: 'Links',
        checked: false,
        pay: false,
        key: 'links',
        mobile: true,
      },
    ],
    additionalOptionsMobileAppDef: [
      {
        id: 1,
        title: 'Custom Design $250 (one-time payment)',
        checked: false,
        key: 'custom_design',
        additionals: true,
        customPrice: true,
        price: 250,
      },
      // {
      //   id: 2,
      //   title: 'Church Community Builder Integration $%price%/month',
      //   checked: false,
      //   pay: true,
      //   key: 'cbb',
      //   additionals: true
      // },
      {
        id: 3,
        title: 'Marketing Package Included',
        checked: false,
        key: 'marketing',
        additionals: true,
      },
      // {
      //   id: 4,
      //   title: 'The Rock RMS Integration $%price%/month',
      //   checked: false,
      //   pay: true,
      //   key: 'rock',
      //   additionals: true
      // },
      // {
      //   id: 5,
      //   title: 'API feed integrations $%price%/month',
      //   checked: false,
      //   pay: true,
      //   key: 'api',
      //   additionals: true
      // }
    ],
    additionalOptionsGivingDef: [
      // {
      //   id: 1,
      //   title: 'Church Community Builder Integration. $%price%/month',
      //   checked: false,
      //   pay: true,
      //   key: 'cbb',
      //   give: true
      // },
      // {
      //   id: 2,
      //   title: 'The Rock RMS Integration. $%price%/month',
      //   checked: false,
      //   pay: true,
      //   key: 'rock',
      //   give: true
      // }
    ],
    prayerWallModule: [
      {
        id: 1,
        title: 'Interactive Prayer Wall — Advanced Plan',
        checked: false,
        prayer: true,
        pay: true,
        website: true,
        price: 25,
        coefficient: {
          1: 3,
          2: 6,
          3: 10,
        },
      },
    ],
    websiteModule: [
      {
        id: 2,
        title: 'Website',
        checked: false,
        pay: true,
        website: true,
        toggleClass: false,
        price: 25,
      },
    ],
    mobileAppModule: [
      {
        id: 3,
        title: 'Mobile App',
        checked: false,
        pay: true,
        mobile: true,
        toggleClass: false,
        price: 25,
        setupFee: 250,
      },
    ],
    tvAppModule: [
      {
        id: 4,
        title: 'TV App + Podcast',
        checked: false,
        pay: true,
        tv: true,
        toggleClass: false,
        price: 50,
        setupFee: 250,
      },
    ],
    giveModule: [
      {
        id: 5,
        title: 'Giving Platform',
        checked: false,
        pay: true,
        give: true,
        toggleClass: false,
        price: 0,
      },
    ],
    pricingObj: {
      firstName: '',
      lastName: '',
      email: '',
      organization: '',
      type: 'church',
      attendance: 0,
      amount: 0,
      period: 'monthly',
      services: {
        additionals: ['cms', 'templates', 'forms'],
      },
      promo: '',
      setup: 0,
    },
    showModalGetStarted: false,
    showModalTemplates: false,
    showBannerBased: false,
    showWidgetBased: false,
    showOnePager: false,
    showModalPrivacyPolicy: false,
    showStraightOffModalSignUp: false,
    showModalScheadule: false,
    showModalWaitGiving: false,
    packageFirst: false,
    packageSecond: false,
    packageThird: false,
    showModalTv: false,
    showModalNewProject: false,
    showModalVerify: false,
    showIframeEmail: false,
    showModalInfo: false,
    showModalInfoError: false,
    validPromoCode: false,
    errorPromo: false,
    timeZoneList: [],
    geoInfo: [],
    countryActive: 'US',
    stateActive: '',
    timeZoneActive: '',
    stepFirstId: '',
    createAdminReady: false,
    createAdminReadyTest: false,
    ifLoaderInfo: false,
    respAdmin: '',
    completedSteps: 0,
    flag: 0,
    adminLink: '',
    userId: '',
    showTerms: false,
    terms: '',
    tags: ['Client'],
    additionalOptionsWebsite: [
      {
        id: 1,
        title: 'Daily Devotions (content) ',
        text: 'Automatically posted daily devotions from <a href="https://www.first15.org" target="_blank">ﬁrst15.org</a> on your website & mobile app',
        checked: false,
        pay: false,
        key: 'devotional',
        website: true,
      },
      {
        id: 2,
        title: 'Custom Design $250 one-time',
        checked: false,
        key: 'custom_design_website',
        // additionals: true,
        customPrice: true,
        website: true,
        price: 250,
      },
    ],
    donorManagementSystem: [
      {
        id: 1,
        title: 'Donor Management System',
        checked: true,
        pay: false,
        key: 'cms',
        additionals: true,
        toggleClass: true,
      },
    ],
    smartTemplates: [
      {
        id: 2,
        title: 'Smart Templates',
        checked: true,
        pay: false,
        key: 'templates',
        additionals: true,
        toggleClass: true,
      },
    ],
    forms: [
      {
        id: 3,
        title: 'Forms',
        checked: true,
        pay: false,
        key: 'forms',
        additionals: true,
        toggleClass: true,
      },
    ],
    templates: [],
    templatesTotal: 0,
  },
  getters: {
    computedPricingObj(state) {
      return state.pricingObj;
    },
    templates(state) {
      return state.templates;
    },
    templatesTotal(state) {
      return state.templatesTotal;
    },
    userId(state) {
      return state.userId;
    },
    prayerWallModule(state) {
      return state.prayerWallModule;
    },
    websiteModule(state) {
      return state.websiteModule;
    },
    additionalOptionsMobileAppDef(state) {
      return state.additionalOptionsMobileAppDef;
    },
    additionalOptionsDef(state) {
      return state.additionalOptionsDef;
    },
    additionalOptionsGivingDef(state) {
      return state.additionalOptionsGivingDef;
    },
    selectAllModules(state) {
      return state.selectAllModules;
    },
    tvAppList(state) {
      return state.tvAppList;
    },
    tvAppModulesList(state) {
      return state.tvAppModulesList;
    },
    audioPodcastList(state) {
      return state.audioPodcastList;
    },
    mobileAppModulesList(state) {
      return state.mobileAppModulesList;
    },
    mobileAppModule(state) {
      return state.mobileAppModule;
    },
    tvAppModule(state) {
      return state.tvAppModule;
    },
    giveModule(state) {
      return state.giveModule;
    },
    donorManagementSystem(state) {
      return state.donorManagementSystem;
    },
    smartTemplates(state) {
      return state.smartTemplates;
    },
    forms(state) {
      return state.forms;
    },
    additionalOptionsWebsite(state) {
      return state.additionalOptionsWebsite;
    },
    tags(state) {
      return state.tags;
    },
    showBannerBased(state) {
      return state.showBannerBased;
    },
    showTerms(state) {
      return state.showTerms;
    },
    terms(state) {
      return state.terms;
    },
    showWidgetBased(state) {
      return state.showWidgetBased;
    },
    showOnePager(state) {
      return state.showOnePager;
    },
    ifLoaderInfo(state) {
      return state.ifLoaderInfo;
    },
    adminLink(state) {
      return state.adminLink;
    },
    completedSteps(state) {
      return state.completedSteps;
    },
    createAdminReady(state) {
      return state.createAdminReady;
    },
    flag(state) {
      return state.flag;
    },
    createAdminReadyTest(state) {
      return state.createAdminReadyTest;
    },
    showIframeEmail(state) {
      return state.showIframeEmail;
    },
    stepFirstId(state) {
      return state.stepFirstId;
    },
    respAdmin(state) {
      return state.respAdmin;
    },
    countryActive(state) {
      return state.countryActive;
    },
    validPromoCode(state) {
      return state.validPromoCode;
    },
    geoInfo(state) {
      return state.geoInfo;
    },
    showModalVerify(state) {
      return state.showModalVerify;
    },
    errorPromo(state) {
      return state.errorPromo;
    },
    showModalGetStarted(state) {
      return state.showModalGetStarted;
    },
    showModalTemplates(state) {
      return state.showModalTemplates;
    },
    showModalTv(state) {
      return state.showModalTv;
    },
    showModalNewProject(state) {
      return state.showModalNewProject;
    },
    showModalInfo(state) {
      return state.showModalInfo;
    },
    showModalInfoError(state) {
      return state.showModalInfoError;
    },
    showModalPrivacyPolicy(state) {
      return state.showModalPrivacyPolicy;
    },
    showStraightOffModalSignUp(state) {
      return state.showStraightOffModalSignUp;
    },
    showModalScheadule(state) {
      return state.showModalScheadule;
    },
    showModalWaitGiving(state) {
      return state.showModalWaitGiving;
    },
    packageFirst(state) {
      return state.packageFirst;
    },
    packageSecond(state) {
      return state.packageSecond;
    },
    packageThird(state) {
      return state.packageThird;
    },
    timeZoneList(state) {
      return state.timeZoneList;
    },
    stateActive(state) {
      return state.stateActive;
    },
    timeZoneActive(state) {
      return state.timeZoneActive;
    },
  },
  mutations: {
    setPricingObj(state, payload) {
      console.log(payload);
      state.pricingObj = payload;
    },
    setTemplates(state, payload) {
      state.templates = payload;
    },
    setTemplatesTotal(state, payload) {
      state.templatesTotal = payload;
    },
    setShowModalGetStarted(state, payload) {
      state.showModalGetStarted = payload;
    },
    setShowModalTemplates(state, payload) {
      state.setShowModalTemplates = payload;
    },
    setUserId(state, payload) {
      state.userId = payload;
    },
    setPrayerWallModule(state, data) {
      state.prayerWallModule = data;
    },
    setWebsiteModule(state, data) {
      state.websiteModule = data;
    },
    setAdditionalOptionsMobileAppDef(state, data) {
      state.additionalOptionsMobileAppDef = data;
    },
    setAdditionalOptionsDef(state, data) {
      state.additionalOptionsDef = data;
    },
    setAdditionalOptionsGivingDef(state, data) {
      state.additionalOptionsGivingDef = data;
    },
    setSelectAllModules(state, data) {
      state.selectAllModules = data;
    },
    setTvAppList(state, data) {
      state.tvAppList = data;
    },
    setTvAppModulesList(state, data) {
      state.tvAppModulesList = data;
    },
    setAudioPodcastList(state, data) {
      state.audioPodcastList = data;
    },
    setMobileAppModulesList(state, data) {
      state.mobileAppModulesList = data;
    },
    setMobileAppModule(state, data) {
      state.mobileAppModule = data;
    },
    setTvAppModule(state, data) {
      state.tvAppModule = data;
    },
    setGiveModule(state, data) {
      state.giveModule = data;
    },
    setDonorManagementSystem(state, data) {
      state.donorManagementSystem = data;
    },
    setSmartTemplates(state, data) {
      state.smartTemplates = data;
    },
    setForms(state, data) {
      state.forms = data;
    },
    setAdditionalOptionsWebsite(state, data) {
      state.additionalOptionsWebsite = data;
    },
    changeTags(state, payload) {
      state.tags = payload;
    },
    setShowBannerBased(state, payload) {
      state.showBannerBased = payload;
    },
    setShowWidgetBased(state, payload) {
      state.showWidgetBased = payload;
    },
    setShowOnePager(state, payload) {
      state.showOnePager = payload;
    },
    changeCompletedSteps(state, payload) {
      state.completedSteps = payload;
    },
    changeShowTerms(state, payload) {
      state.showTerms = payload;
    },
    setTerms(state, payload) {
      state.terms = payload;
    },
    changeAdminLink(state, payload) {
      state.adminLink = payload;
    },
    changeFlag(state, payload) {
      state.flag = payload;
    },
    setCreateAdminReady(state, payload) {
      state.createAdminReady = payload;
    },
    setCreateAdminReadyTest(state, payload) {
      state.createAdminReadyTest = payload;
    },
    setStepFirstId(state, payload) {
      state.stepFirstId = payload;
    },
    setRespAdmin(state, payload) {
      state.respAdmin = payload;
    },
    setShowIframeEmail(state, payload) {
      state.showIframeEmail = payload;
    },
    setIfLoaderInfo(state, payload) {
      state.ifLoaderInfo = payload;
    },
    setShowModalVerify(state, payload) {
      state.showModalVerify = payload;
    },
    setShowModalInfoError(state, payload) {
      state.showModalInfoError = payload;
    },
    setCountryActive(state, payload) {
      state.countryActive = payload;
    },
    setErrorPromo(state, payload) {
      state.errorPromo = payload;
    },
    setStateActive(state, payload) {
      state.stateActive = payload;
    },
    setTimeZoneActive(state, payload) {
      state.timeZoneActive = payload;
    },
    setValidPromoCode(state, payload) {
      state.validPromoCode = payload;
    },
    setShowModalPrivacyPolicy(state, payload) {
      state.showModalPrivacyPolicy = payload;
    },
    setShowStraightOffModalSignUp(state, payload) {
      state.showStraightOffModalSignUp = payload;
    },
    setShowModalScheadule(state, payload) {
      state.showModalScheadule = payload;
    },
    setShowModalWaitGiving(state, payload) {
      state.showModalWaitGiving = payload;
    },
    setShowModalTv(state, payload) {
      state.showModalTv = payload;
    },
    setShowModalInfo(state, payload) {
      state.showModalInfo = payload;
    },
    setShowModalNewProject(state, payload) {
      state.showModalNewProject = payload;
    },
    setPackageFirst(state, payload) {
      state.packageFirst = payload;
    },
    setPackageSecond(state, payload) {
      state.packageSecond = payload;
    },
    setTimezoneList(state, data) {
      state.timeZoneList = data;
    },
    setGeoInfo(state, data) {
      state.geoInfo = data;
    },
  },
});
