// const indexPage = () => import('@/indexPage')
const vipPage = () => import('@/vipPage');
const vip2Page = () => import('@/vip2Page');
const pricingPage = () => import('@/pricingPage');
const mobilePage = () => import('@/mobilePage');
const tvPage = () => import('@/tvPage');
const pricingStep1 = () => import('@/pricingStep1');
const pricingStep2 = () => import('@/pricingStep2');
const epicPay = () => import('@/epicPay');
const indexPage2 = () => import('@/indexPage2');
const confirmationPage = () => import('@/confirmationPage');
const homePage = () => import('@/homePage');
const homePageUSA = () => import('@/homePageUSA');
const meetingconfirmation = () => import('@/meetingconfirmation');
const competitordownload = () => import('@/competitordownload');
const registrationconfirmation = () => import('@/registrationconfirmation');

export const routes = [
  {
    path: '/',
    name: 'index',
    component: homePageUSA,
    meta: {
      title: '#1 Website builder for churches and ministries',
      metaTags: [{
        name: 'description',
        content: 'Set website goals and hit them with all-in-one website building platform. Partnering with churches and nonprofit organizations of any size across the world.',
      }],
    },
  },
  {
    path: '/limitedtimeoffer',
    name: 'limitedtimeoffer',
    component: homePage,
    meta: {
      title: '#1 Website builder for churches and ministries',
      metaTags: [{
        name: 'description',
        content: 'Set website goals and hit them with all-in-one website building platform. Partnering with churches and nonprofit organizations of any size across the world.',
      }],
    },
  },
  {
    path: '/vip',
    name: 'vip',
    component: vipPage,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
      metaTags2: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
      }],
    },
  },
  {
    path: '/vip2',
    name: 'vip2',
    component: vip2Page,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
      metaTags2: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
      }],
    },
  },
  {
    path: '/pricing-page',
    name: 'pricingPage',
    component: pricingPage,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
    },
  },
  {
    path: '/mobile',
    name: 'mobilePage',
    component: mobilePage,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
      metaTags2: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
      }],
    },
  },
  {
    path: '/tv',
    name: 'tvPage',
    component: tvPage,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
      metaTags2: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
      }],
    },
  },
  {
    path: '/websites2',
    name: 'websitesPage2',
    component: indexPage2,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
      metaTags2: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
      }],
    },
  },
  {
    path: '/websites',
    name: 'websitesPage',
    component: homePageUSA,
    meta: {
      title: '#1 Website builder for churches and ministries',
      metaTags: [{
        name: 'description',
        content: 'Set website goals and hit them with all-in-one website building platform. Partnering with churches and nonprofit organizations of any size across the world.',
      }],
    },
  },
  {
    path: '/mobile2',
    name: 'mobilePage2',
    component: mobilePage,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
      metaTags2: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
      }],
    },
  },
  {
    path: '/tv2',
    name: 'tvPage2',
    component: tvPage,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
      metaTags2: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
      }],
    },
  },
  {
    path: '/confirmation',
    name: 'confirmationPage',
    component: confirmationPage,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1, maximum-scale=1',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
    },
  },
  {
    path: '/pricing-step-1',
    name: 'pricingStep1',
    component: pricingStep1,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
    },
  },
  {
    path: '/pricing-step-2',
    name: 'pricingStep2',
    component: pricingStep2,
    meta: {
      metaTags: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0',
      },
      {
        name: 'description',
        content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
      },
      ],
    },
  },
  {
    path: '/epic-pay',
    name: 'epicPay',
    component: epicPay,
    meta: {
      metaTags: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0',
      },
      {
        name: 'description',
        content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
      }],
    },
  },
  {
    path: '/index2',
    name: 'index2',
    component: indexPage2,
    meta: {
      metaTags: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0',
      },
      {
        name: 'description',
        content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
      }],
      metaTags2: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=0.27, maximum-scale=0.27',
      }],
    },
  },
  {
    path: '/meetingconfirmation',
    name: 'meetingconfirmation',
    component: meetingconfirmation,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1, maximum-scale=1',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
    },
  },
  {
    path: '/competitordownload',
    name: 'competitordownload',
    component: competitordownload,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1, maximum-scale=1',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
    },
  },
  {
    path: '/registrationconfirmation',
    name: 'registrationconfirmation',
    component: registrationconfirmation,
    meta: {
      metaTags: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1, maximum-scale=1',
        },
        {
          name: 'description',
          content: 'Grow your church or ministry with the most powerful platform yet. Get a new church website, crm, giving platform, forms, graphics & media platform. Integrated solution starting from only $25/month. No setup fees. All-in-one or unbundled! You will never look back.',
        },
      ],
    },
  },
  // {
  //   path: '/price-comparison',
  //   name: 'priceComparison',
  //   component: priceComparison,
  //   meta: {
  //     metaTags: [{
  //       name: 'viewport',
  //       content: 'width=device-width,initial-scale=0.3, maximum-scale=0.3'
  //     }]
  //   }
  // }
];
