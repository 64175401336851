<template>
  <div
    v-shortkey="['esc']"
    class="modal-close-page"
    @click.stop="$emit('closeModal')"
    @shortkey="$emit('closeModal')"
  >
    <div
      class="modal-close-page-container"
      @click.stop
    >
      <transition name="fade-block-wait">
        <div v-if="!isSuccess">
          <div class="modal-close-page__close">
            <i
              class="icon-delete"
              @click="$emit('closeModal')"
            />
          </div>
          <div class="modal-close-page__title">
            Wait!
          </div>
          <div class="modal-close-page__text">
            <div class="modal-close-page__text_mobile">
              Lock Your Discount
            </div>
            <div class="modal-close-page__text_mobile">
              And Get a Free Demo + Consultation!
            </div>
          </div>
          <div class="modal-close-page-content">
            <img
              :src="require('./../../assets/images/websiteReady.svg')"
              class="modal-close-page-content__image"
            >
            <div class="modal-close-page-content-form">
              <div class="modal-close-page-content-form__group">
                <input
                  v-model="loginForm.name"
                  v-focus
                  class="modal-close-page-content-form__text-field"
                  name="text"
                  type="name"
                  placeholder="Your Name"
                  @click.stop.prevent
                />
              </div>
              <div class="modal-close-page-content-form__group">
                <input
                  v-model="loginForm.email"
                  class="modal-close-page-content-form__text-field"
                  :class="{'error': validator.errors.has('email')}"
                  required
                  name="email"
                  type="email"
                  placeholder="Your Email"
                  @keydown="handelerKyeDown"
                  @click.stop.prevent
                />
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('email')"
                    class="help-modal is-danger"
                  >{{ validator.errors.first('email') }}</span>
                </transition>
              </div>
              <button
                class="modal-close-page-content-form__button btn-modal-form-submit"
                @click.prevent.stop="onButtonClick"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div
          v-else
          class="modal-close-page-thank"
        >
          <div class="modal-close-page__close">
            <i
              class="icon-delete"
              @click="$emit('closeModal')"
            />
          </div>
          <img
            :src="require('./../../assets/images/fankYou.svg')"
            class="modal-close-page-thank__image"
          >
          <div class="modal-close-page-thank__title">
            Thank you!
          </div>
          <div class="modal-close-page-thank__text">
            <div class="modal-close-page-thank__text_mobile">
              Please pick a time for your
            </div>
            <div class="modal-close-page-thank__text_mobile">
              free consultation
            </div>
          </div>
          <a
            href="https://calendly.com/d/cmv-mf2-9zh/demo-of-impactfactors-vip-50-discount?month=2022-01"
            target="_blank"
            class="modal-close-page-content-form__button modal-close-page-thank__button btn-modal-form-schedule"
          >
            Schedule a Zoom Meeting
          </a>
        </div>
      </transition>
      <img
        class="modal-close-page__logo"
        src="./../../home-assets/img/logo/logo-white.svg"
      >
    </div>
  </div>
</template>

<script>
import amoCrmApi from '@/api/amoCrmApi';
import { Validator } from 'vee-validate';

export default {
  name: 'ModalsClosePage',
  data() {
    return {
      validator: {},
      loginForm: {
        email: '',
        name: '',
      },
      isSuccess: false,
    };
  },
  beforeMount() {
    this.validator = Validator.create();
    const dict = {
      en: {
        custom: {
          email: {
            required: 'The Email is required.',
            email: 'The Email field must be a valid email.',
          },
        },
      },
    };
    this.validator.localize(dict);
    this.validator.attach({ name: 'email', rules: { required: true, email: true } });
  },
  methods: {
    handelerKyeDown(e) {
      if (e.key === 'Enter') {
        this.onButtonClick();
      }
    },
    onButtonClick() {
      const { email, name } = this.loginForm;
      this.validator.validateAll({
        email,
      }).then((result) => {
        if (result) {
          this.isSuccess = !this.isSuccess;
          // const data = {
          //   email,
          //   name,
          // };
          const data = {
            fields: [
              { name: 'Your Name', value: name, position: 1 },
              { name: 'Email', value: email, position: 2 },
            ],
          };
          amoCrmApi.sendLeadsToAmoSrm({ data, id: 8 }).then();
        }
      });
    },
    onMeeting() {

    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/utils/variables";

.modal-close-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba($color-black, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__close {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 15px;
    right: 15px;
    transition: color .2s ease-in;
    cursor: pointer;

    &:hover {
      color: $color-turquoise;
    }
  }

  &__title {
    font-size: 9.48vh;
    line-height: 1.04;
    font-weight: 700;
    color: $color-mirage;
    text-transform: uppercase;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 80px;
      line-height: 88px;
    }

    @media (max-width: 540px) {
      font-size: 60px;
      line-height: 68px;
    }

    @media (max-width: 375px) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__text {
    font-size: 4.21vh;
    line-height: 1.575;
    font-weight: 400;
    color: $color-gray;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 44px;
    }

    @media (max-width: 540px) {
      font-size: 24px;
      line-height: 40px;

      &_mobile {
        display: inline;
      }
    }

    @media (max-width: 375px) {
      font-size: 20px;
      line-height: 36px;
    }
  }

  &-content {
    display: flex;
    margin-bottom: 17vh;
    margin-top: 11px;

    &__image {
      width: 31.29vh;
      max-width: 338px;

      @media (max-width: 540px) {
        width: 266px;
      }

      @media (max-width: 540px) {
        width: 246px;
      }
    }

    &-form {
      margin-left: 36px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      &__group {
        position: relative;
        margin-bottom: 16px;
      }

      &__text-field {
        width: 344px;
        padding: 11px 18px;
        border-radius: 8px;
        border: 1px solid $color-mercury;
        font-size: 16px;
        transition: box-shadow .2s ease-in;

        &:focus {
          box-shadow: 0 -2px 0 $color-turquoise inset !important;
        }

        @media (max-width: 991px) {
          width: 300px;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &__button {
        background: $color-turquoise;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: $color-white;
        padding: 13px 0;
        width: 100%;
        max-width: 100%;
        text-decoration: none;
        transition: opacity .2s ease-in, background .2s ease-in;
        user-select: none;
        appearance: none;
        outline: none!important;

        &:hover {
          opacity: .8;
        }

        &:active {
          background: $color-elm;
          opacity: 1;
        }

      }

      @media (max-width: 650px) {
        margin-left: 0;
        margin-top: 14px;
        width: 328px;
      }
    }

    @media (max-width: 768px) {
      margin-bottom: 120px;
    }

    @media (max-width: 650px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
    }

    @media (max-width: 375px) {
      margin-bottom: 30px;
    }
  }

  &__logo {
    @media (max-width: 540px) {
      max-width: 136px;
    }
  }

  &-thank {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__image {
      min-width: 16.57vh;
      max-width: 16.57vh;
    }

    &__title {
      font-size: 5.9vh;
      line-height: 1.142;
      font-weight: 700;
      color: $color-mirage;
      margin-top: 34px;
      margin-bottom: 25px;

      @media (max-width: 540px) {
        font-size: 48px;
        line-height: 52px;
        margin-bottom: 10px;
      }

    }

    &__text {
      font-size: 2.5vh;
      line-height: 1.5;
      font-weight: 400;
      color: $color-gray;
      display: inline;

      @media (max-width:540px) {
        &_mobile {
          display: block;
        }
      }
    }

    &__button {
      max-width: 244px;
      margin-top: 46px;
      margin-bottom: 80px;
      padding-left: 15px;
      padding-right: 15px;
      color: $color-white!important;

      @media (max-width:540px) {
        max-width: 328px;
        margin-top: 136px;
        margin-bottom: 60px;
      }
    }

    @media (max-width:540px) {
      width: 100vw;
    }
  }
}

.modal-close-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: $color-white;
  padding: 50px 100px;
  text-align: center;
  border-radius: 8px;
  max-width: calc(100% - 50px);
  min-width: 875px;

  @media (max-width: 540px) {
    max-width: 100%;
    padding: 0 24px;
    height: 100%;
    border-radius: 0;
  }
}

.error {
  border-color: $color-punch !important;
  background-color: $color-white !important;
}
.help-modal {
  position: absolute;
  top: -19px;
  left: 0;
  font-size: 12px;
  color: $color-punch;
}

</style>
<!-- Created by zas on 14.06.2021  -->
