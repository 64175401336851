<template>
  <div id="app">
    <transition
      appear
      :name="$route.name !== 'pricingPage' ? 'fade-slide' : 'fade-start'"
    >
      <header-block
        v-if="$route.name !== null && $route.name !== 'vip' && $route.name !== 'vip2'
          && $route.name !== 'mobilePage' && $route.name !== 'tvPage'
          && $route.name !== 'mobilePage2' && $route.name !== 'tvPage2' && $route.name !== 'limitedtimeoffer'
          && $route.name !== 'meetingconfirmation' && $route.name !== 'competitordownload'
          && $route.name !== 'registrationconfirmation' && $route.name !== 'index'"
        :showModalVideo.sync="showModalVideo"
      />
    </transition>
    <div
      class="wrapper"
      :class="{'wrapper_flex': $route.name === 'competitordownload'}"
    >
      <!--<transition name="fade-pricing">-->
      <router-view
        :showModalVideo.sync="showModalVideo"
        :windowLoaded="windowLoaded"
        :isPrayerwall="isPrayerwall"
      />
      <!--      :isPrayerwall="isPrayerwall"-->
      <!--</transition>-->
    </div>
    <transition
      v-if="$route.name !== 'pricingStep1' && $route.name !== 'meetingconfirmation' && $route.name !== 'competitordownload'
        && $route.name !== 'registrationconfirmation'"
      appear
      name="fade-footer"
    >
      <footerBlock
        v-if="$route.name !== null && $route.name !== 'vip' && $route.name !== 'limitedtimeoffer'
          && $route.name !== 'index'"
      />
    </transition>
    <transition
      name="fade-block-wait"
      appear
      mode="out-in"
    >
      <modal-close-page
        v-if="showModalClosePage"
        @closeModal="showModalClosePage = false"
      >
      </modal-close-page>
    </transition>
    <!---->
  </div>
</template>

<script>
import modalClosePage from '@/components/modals/ModalsClosePage';

const headerBlock = () => import('@/components/headerBlock');
const footerBlock = () => import('@/components/footerBlock');

export default {
  name: 'App',
  components: {
    footerBlock,
    headerBlock,
    modalClosePage,
  },
  data() {
    return {
      showModalVideo: false,
      windowLoaded: false,
      showModalClosePage: false,
      isPrayerwall: false,
    };
  },
  watch: {
    $route(newVal) {
      window.scroll(0, 0);
      const { meta: { metaTags2 = [] } = {} } = newVal;
      metaTags2.map((tagDef) => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-router-controlled', '');
        return tag;
      })
        .forEach((tag) => document.head.appendChild(tag));
      setTimeout(() => {
        window.scroll(0, 0);
        console.log(window.scrollY);
      }, 600);
    },
  },
  mounted() {
    if (window.outerWidth <= 1460 && window.outerWidth > 1191) {
      document.getElementById('app').style.zoom = '80%';
    }
    if (!this.isPrayerwall) {
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieList = decodedCookie.split(';');
      const cookieIndex = cookieList.findIndex((cookie) => cookie.includes('showCloseModal'));
      const isShowModalClosePage = cookieIndex !== -1;
      if (!isShowModalClosePage) {
        document.addEventListener('mouseleave', this.handlerMouseLeave);
      }
    }
  },
  beforeMount() {
    const { search } = window.location;
    const queryUrlParam = 'prayerwall';
    this.isPrayerwall = search.includes(queryUrlParam);
    if (this.isPrayerwall) {
      document.body.classList.add('prayerwall-body');
      document.title = 'Interactive Prayer Wall widget for churches & ministries';
    }

    window.addEventListener('load', () => {
      this.windowLoaded = true;
      const [preloaderWapper] = document.getElementsByClassName('preloader-wapper');
      if (preloaderWapper) {
        preloaderWapper.classList.add('loaded');
        setTimeout(() => {
          const [preloaderMain] = document.getElementsByClassName('preloader-main');
          preloaderMain.remove();
        }, 1000);
      }
    });
    const { location: { pathname } } = window;
    if (pathname === '/confirmation') {
      this.$gtag.event('conversion', {
        groups: 'form',
        send_to: 'AW-1011441392/-RmMCNmbqvUBEPC9peID',
        transaction_id: '',
      });
    } else {
      this.$gtag.event('pageview', {
        groups: 'app',
      });
      this.$gtag.event('Lead Registration', {
        groups: 'app',
      });
    }
  },
  methods: {
    handlerMouseLeave(e) {
      const { clientY } = e;
      const excludedRoutes = [
        'pricingPage',
        'confirmationPage',
        'pricingStep1',
        'pricingStep2',
        'epicPay',
        'meetingconfirmation',
        'competitordownload',
        'registrationconfirmation',
      ];
      const isExcludedRoutes = excludedRoutes.includes(this.$route.name);
      if (clientY < 1 && !isExcludedRoutes) {
        this.showModalClosePage = true;
        const currentYear = new Date().getFullYear();
        const cookieYear = new Date(new Date().setFullYear(currentYear + 1));
        document.cookie = `showCloseModal=true; expires=${cookieYear}; path=/;`;
        document.removeEventListener('mouseleave', this.handlerMouseLeave);
      }
    },
  },
  // created () {
  //   window.scrollTo(0, 0)
  //   console.log(window.scrollY)
  // }
};
</script>

<style lang="scss">
  @import './assets/css/style';
  .wrapper {
    padding-top: 126px;
    flex-grow: 1;

    &_flex {
      display: flex;
    }

    @media (max-width: 767px) {
      padding-top: 0;
    }

    &_pt {
      padding-top: 25px;
    }
  }

  @font-face {
    font-family: 'SF-Pro-Display-Regular';
    src: url('./assets/fonts/Sf-pro-display-regular.woff2');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'SF-Pro-Display-Medium';
    src: url('./assets/fonts/SF-Pro-Display-Medium.woff2');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'SF-Pro-Display-Bold';
    src: url('./assets/fonts/SF-Pro-Display-Bold.woff2');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'SF-Pro-Display-Black';
    src: url('./assets/fonts/SF-Pro-Display-Black.woff2');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'MullerRegular';
    src: url('./assets/fonts/MullerRegular.woff2');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'MullerMedium';
    src: url('./assets/fonts/MullerMedium.woff2');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'SFUIDisplay-Semibold';
    src: url('./assets/fonts/SFUIDisplay-Semibold.woff2');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'Bradley-Hand-Bold';
    src: url('./assets/fonts/Bradley-Hand-Bold.woff2');
    font-weight: normal;
    font-style: normal;

  }
  @font-face {
    font-family: 'AFuturaRound';
    src: url('./assets/fonts/AFuturaRound.woff2');
    font-weight: normal;
    font-style: normal;

  }
</style>
